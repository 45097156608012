.bruh {
  font-size: small;
  margin-left: 89.5vw;
  margin-top: -0.25vh;
}
.hello {
  margin-left: 5vw;
}

.copy {
  text-align: center;
  padding: 10px 0;
  
  background-color: #1DB954; 
  color: #191414;
  font-size: 15px;
  position: fixed; 
  bottom: 0;
  font-weight: 700;
  width: 100%;
}


.App {
  color: white;
  
}

.subheader {
  font-size: 150%;
  margin-left: 0.5%;
  color: white;
  font-family: 'custom', sans-serif;
}

.main {
  margin-left: 3%;
  display: flex;
  color: white;
}

.App.apple-theme {
  background-color: white;
  color: #FA233B;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.App.spotify-theme {
  background-color: #191414;
  color: white;
}
.copy.apple-theme{
  background-color: #FA233B;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-size: 17px;
  
}
.copy-spotify-theme{
  background-color:#FA233B;
}

.bruh.apple-theme{
  color: black;
}

.mobile-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 97%;
  color: #1DB954 ;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  margin-top: 50vh;
  text-align: center;
}

@media (min-width: 769px) {
  .mobile-warning {
    display: none; 
  }
  
}



