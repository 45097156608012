.imagestuff{
    height: 350px;
    width: 450px;
    padding: 0 10px;
    object-fit: cover;
    background-color: hsl(0, 11%, 9%);
    cursor: pointer;
}
.images{
    object-fit: cover;
}

.banner-wrapper{
    width: 100%;
    height: 350px;
    background-color: #1DB954;
    overflow: hidden;
}

.banner-wrapper .wrapper .images{
    display: flex;
    animation: swipe 25000ms linear infinite;
}
.banner-wrapper:hover .wrapper .images{
    animation-play-state: paused;
}
.banner-wrapper .wrapper{
    display: flex;
}

@keyframes swipe{
    0%{
        transform:translateX(0);
       
    }
    100%{
        transform: translateX(-100%);
       
    }
}

.imagestuff.apple-theme{
    background-color: white;
}




