.Home{
    background-color:#191414;
    width: 72vw;
    height: 88vh;
    margin-left: 10%;
    margin-top: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
} 

.HEADER1{
    margin-top: -25%;
    font-size: 250%;
    font-style: italic;
}

.text-div{
    text-align: center;
    align-items: center;
    margin-left: 20%;
    margin-top: -44vh;
}

.Home.apple-theme{
    background-color: white;
    height: 88vh;
    margin-top: 0vh;
}

.text-div.apple-theme{
    background-color: white;
    color: #FA233B;
}



