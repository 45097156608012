.sidenav.apple-theme {
  background-color:#FA233B;
  margin-top: 5.90%;
  overflow: hidden;
  height: 81vh;
  width: 16vw;
}

.label.apple-theme{
  font-size: 130%;
}

.mainContact .apple-theme{
  margin-bottom: 1vh;
}

.sidenav.apple-theme a {
  color: white;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 200%;
}

.sidenav.apple-theme .label {
  color: white;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.Contact.apple-theme{
  color: black;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 210%;
}

.sidenav.spotify-theme {
  background-color: #191414; 
}

.sidenav.spotify-theme a {
  color: #1DB954; 
}

.sidenav.spotify-theme .label {
  color: #1DB954;
}

.sidenav {
  height: 80vh;
  width: 16vw;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  top: 0;
  left: 0;
  overflow-x: hidden;
  margin-left: 2%;
  margin-top: 6.5%;
  position: absolute;
  text-align: left;
}

.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 30px;
  display: block;
  transition: 0.3s;
  font-family: 'custom', sans-serif;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.Contact {
  color: white;
  font-family: 'custom', sans-serif;
  margin-left: 3%;
}

.git:hover,
.linked:hover,
.mail:hover {
  color: #f1f1f1;
  transition: 0.3s;
}

.mainContact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 55%;
  margin-left: 1%;
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.iconRow {
  display: flex;
  align-items: center;
}

.label {
  margin-left: 1vw;
  font-family: 'custom', sans-serif;
  font-size: 20px;
}

