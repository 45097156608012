.about-main {
    background-color: #191414;
    width: 83%; 
    margin: 0 15vw 0; 
    margin-top: 0;
    height: 85vh;
    font-weight: 700;
    padding-left: 10px;
    overflow-y: auto;
    font-size: 1.2em; 
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 20px; 
    box-sizing: border-box;
}

.part1-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    margin-bottom: 20px; 
}

.image-about {
    flex: 1; 
    max-width: 30%;
}

.image-about img {
    width: 100%; 
    height: auto; 
    border-radius: 5px; 
}

.text {
    flex: 2; 
    color: white;
    text-align: left;
    font-size: 1em; 
    margin-top: -1vh;
    line-height: 1.6; 
}

.part2 {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    align-items: flex-start; 
    margin-top: 30px;
}

.image8 {
    flex: 0.5; 
    max-width: 45%; 
    border-radius: 5px; 
}

.image8 img {
    width: 100%; 
    border-radius: 5px;
    height: auto; 
}

.text2 {
    flex: 1; 
    text-align: left;
    color: white;
    font-size: 1em; 
    line-height: 1.6; 
}

.text3 {
    text-align: center;
    margin-top: 20px;
    font-size: 1em; 
}

.resume-link {
    color: white;
    text-decoration: underline;
}

.about-main.apple-theme {
    background-color: white;
    color: #FA233B;
}

.text.apple-theme, .resume-link.apple-theme, .text2.apple-theme, .text3.apple-theme {
    color: #FA233B;
}




@media (max-width: 1024px) {
    .about-main {
        width: 90%;
        padding: 15px;
    }

    .part1-container, .part2 {
        flex-direction: column; 
    }

    .image-about, .image8 {
        max-width: 100%; 
        margin-bottom: 20px; 
    }

    .text, .text2 {
        text-align: center;
    }
}