.blog-main{
    background-color:#191414;
    width: 75vw;
    height: 84vh;
    margin-left: 15%;
    margin-top: 0;
    text-align: center;
    font-weight: 700;
    font-size: 200%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.blog-text{
    margin-top: -20vh;
}
.blog-main.apple-theme{
    background-color: white;
    height: 85vh;
}